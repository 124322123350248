<template>
  <header class="titlebar" :class="{ '--transparent': isTransparent }">
    <nav class="titlebar__nav menu main-menu">
      <AppLogo />
      <div class="titlebar__nav-links main-menu__links">
        <router-link
          v-for="(link, key) in navLinks"
          :key="key + '_titlebarLink'"
          :to="link.route"
          class="titlebar__nav-link main-menu__link"
          v-html="link.content"
        />
      </div>
    </nav>

    <div class="titlebar__mobile">
      <AppLogo />
      <i
        class="titlebar__mobile-hamburger far fa-bars"
        @click="toggleMobileMenu"
      ></i>

      <nav
        class="titlebar__mobile-nav menu mobile-menu"
        :class="{ '--active': mobileMenuActive }"
      >
        <i
          class="titlebar__mobile-nav-close far fa-times"
          @click="toggleMobileMenu"
        ></i>

        <AppLogo color="white" @click.native="toggleMobileMenu" />

        <div class="titlebar__mobile-nav-links mobile-menu__links">
          <router-link
            to="/"
            class="titlebar__mobile-nav-link"
            @click.native="toggleMobileMenu"
            >Home</router-link
          >
          <router-link
            v-for="(link, key) in navLinks"
            :key="key + '_titlebarMobileLink'"
            :to="link.route"
            v-html="link.content"
            class="titlebar__mobile-nav-link mobile-menu__link"
            @click.native="toggleMobileMenu"
          />
        </div>

        <div class="titlebar__mobile-nav-social-media-links">
          <a
            v-for="socialMedia in socialMedias"
            :key="socialMedia.name + '_socialMedia'"
            class="titlebar__mobile-nav-social-media-link"
            :href="socialMedia.link"
            target="_blank"
          >
            <i :class="socialMedia.icon" />
          </a>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import AppLogo from "./AppLogo";

let transparentScrollTopPosition = 30;

export default {
  name: "TheTitlebar",
  props: {},
  components: {
    AppLogo
  },
  data() {
    return {
      isTransparent:
        (this.$route.name === "home" || this.$route.name === "contact") &&
        document.documentElement.scrollTop < transparentScrollTopPosition,
      mobileMenuActive: false,
      navLinks: {
        partners: {
          content: "Partners",
          route: "/partners"
        },
        about: {
          content: "About",
          route: "/about"
        },
        contact: {
          content: "Contact",
          route: "/contact"
        }
      },
      socialMedias: [
        {
          name: "Facebook",
          icon: "fab fa-facebook-f",
          link: "https://facebook.com/jumpfeed"
        },
        {
          name: "Twitter",
          icon: "fab fa-twitter",
          link: "https://twitter.com/jumpfeed"
        },
        {
          name: "Instagram",
          icon: "fab fa-instagram",
          link: "https://instagram.com/jump_feed"
        },
        {
          name: "LinkedIn",
          icon: "fab fa-linkedin",
          link: "https://linkedin.com/company/jumpfeedmarketing"
        }
      ]
    };
  },
  watch: {
    $route(to) {
      if (to.name === "home" || to.name === "contact") {
        this.isTransparent = true;
      }
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.isTransparent =
        (this.$route.name === "home" || this.$route.name === "contact") &&
        document.documentElement.scrollTop < transparentScrollTopPosition;
    },
    toggleMobileMenu() {
      this.mobileMenuActive = !this.mobileMenuActive;
    }
  }
};
</script>

<style scoped lang="scss">
.titlebar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  @include displayFlex(row, center, center);
  height: $header-size;
  z-index: 1000;
  background: #0f0e0feb;
  @include transition(background, 0.3s);

  &.--transparent {
    background: transparent;
  }

  .titlebar__nav {
    @include containWidth();
    @include displayFlex(row, space-between, center);
    width: 100%;
    padding: 0 $gap-very-large;
    font-size: 0.9em;

    @include screenMedium {
      display: none;
    }

    .titlebar__nav-links {
      .titlebar__nav-link {
        margin: 0 $gap-standard;
        text-transform: uppercase;
        color: $base;
        text-decoration: none;
        font-weight: 300;
        font-size: 0.92em;
        @include hoverUnderline($accent);

        &:last-of-type {
          margin-right: 0;
        }

        /*&.router-link-active {
          color: $accent;
          font-weight: 500;
        }*/
      }
    }
  }

  .titlebar__mobile {
    position: relative;
    display: none;
    width: 100%;
    padding: 0 $gap-large;

    @include screenMedium {
      @include displayFlex(row, space-between, center);
    }

    .titlebar__mobile-hamburger {
      font-size: 1.5em;
      cursor: pointer;
    }

    .titlebar__mobile-nav {
      @include displayFlex(column, space-between, flex-start);
      position: fixed;
      top: 0;
      bottom: 0;
      right: calc(-2 * #{$gap-large});
      z-index: -1000;
      width: 0;
      height: 100vh;
      opacity: 0;
      padding: $gap-large;
      background: $primary-light;
      color: $base;
      box-shadow: -11px 0px 60px -10px rgba(0, 0, 0, 0.85);

      @include transition(all);

      &.--active {
        z-index: 100;
        opacity: 1;
        width: calc(100% - #{$gap-large});
        right: 0;
      }

      .titlebar__mobile-nav-close {
        position: absolute;
        top: $gap-large;
        right: $gap-large;
        font-size: 1.5em;
        cursor: pointer;
      }

      .titlebar__mobile-nav-links {
        @include displayFlex(column);

        .titlebar__mobile-nav-link {
          position: relative;
          z-index: 2;
          margin: $gap-standard 0;
          color: $base;
          font-size: 1.3em;
          text-transform: uppercase;
          font-weight: 300;
          text-decoration: none;
          @include hoverUnderline($accent);

          &:first-of-type {
            margin-top: 0;
          }
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      .titlebar__mobile-nav-social-media-links {
        @include screenSmall {
          margin-bottom: 7rem;
        }

        .titlebar__mobile-nav-social-media-link {
          text-decoration: none;
          color: $accent;
          margin: 0 $gap-standard;

          @include hoverDarken();

          &:first-of-type {
            margin-left: 0;
          }

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
  }
}
</style>
