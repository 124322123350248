import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/About.vue")
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/Contact.vue")
  },
  {
    path: "/partners",
    name: "partners",
    beforeEnter(to, from, next) {
      store.commit("setGoToPartnersFlag", true);
      next("/");
    }
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("../views/PrivacyPolicy.vue")
  },
  {
    path: "/tos",
    name: "tos",
    component: () => import("../views/TermsOfService.vue")
  },
  {
    path: "*",
    name: "404",
    component: () => import("../views/ErrorScreen.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

router.afterEach(() => {
  // TODO: Fix smoothness of this
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
});

export default router;
