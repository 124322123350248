import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    goToPartners: false
  },
  mutations: {
    setGoToPartnersFlag(state, payload) {
      if (typeof payload === "boolean") {
        state.goToPartners = payload;
      }
    }
  },
  actions: {},
  modules: {}
});
