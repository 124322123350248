<template>
  <section class="home">
    <div class="home__video-container">
      <video class="home__video" autoplay muted loop playsinline preload="auto">
        <source :src="backgroundVideoMP4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>

    <div class="home__atf-content">
      <h1
        class="home__cta-header"
        data-aos="zoom-out-right"
        data-aos-delay="50"
        data-aos-duration="500"
      >
        Buy Media, <span class="--color-orange">Better.</span>
      </h1>
      <p
        class="home__cta-text"
        data-aos="zoom-out-left"
        data-aos-delay="500"
        data-aos-duration="500"
      >
        Jumpfeed uses performance-based programmatic media buying to connect
        online shoppers with the most relevant products in real-time. In 2020,
        we converted 4.96M+ shoppers.
      </p>
    </div>

    <div ref="partners" class="partners-content">
      <h5 class="--text-uppercase --text-weight-light">
        Select Client Partners
      </h5>
      <div class="partners-grid">
        <div
          v-for="(logoData, i) in partnerLogos"
          :key="logoData.partner + '_partnerLogo'"
          class="partner-logo-container"
        >
          <img
            :src="logoData.logo"
            :alt="logoData.partner + ' Logo'"
            class="partner-logo-image"
            data-aos="fade-in"
            :data-aos-delay="i * 100"
          />
        </div>
      </div>
    </div>

    <div ref="howTechWorks" class="how-tech-works">
      <div class="how-tech-works__content" data-aos="fade-in">
        <div class="how-tech-works__text">
          <h1 class="how-tech-work__header --text-uppercase" style="font-weight: 400">
            How The Tech Works
          </h1>
          <p>
            Jumpfeed connects your inventory directly to all major display and
            social networks. Our technology captures and analyzes data on your
            products in real-time, and intelligently serves them on the channels
            where they are most likely to convert.
          </p>
        </div>
        <picture class="how-tech-works__graphic-container">
          <source :srcset="howTechWorksGraphicWEBP" type="image/webp" />
          <source :srcset="howTechWorksGraphic" type="image/png" />
          <img :src="howTechWorksGraphic" class="how-tech-works__graphic" />
        </picture>
      </div>
    </div>
  </section>
</template>

<script>
import BackgroundVideoMP4 from "@/assets/videos/JF-Cover-Video.mp4";

import AutoNationLogo from "@/assets/images/partner_logos/AutoNation.png";
import Web2CarzLogo from "@/assets/images/partner_logos/Web2Carz.png";
import MediaNetLogo from "@/assets/images/partner_logos/MediaNet.png";
import TalrooLogo from "@/assets/images/partner_logos/Talroo.png";
import TalentComLogo from "@/assets/images/partner_logos/TalentCom.png";
import ActivEngageLogo from "@/assets/images/partner_logos/ActivEngage.png";

import HowTechWorksGraphic from "@/assets/images/How-Tech-Works-Graphic.png";
import HowTechWorksGraphicWEBP from "@/assets/images/How-Tech-Works-Graphic.webp";

export default {
  name: "home",
  metaInfo: {
    titleTemplate: "%s | Buy Media, Better.",
    link: [{ rel: "canonical", href: "https://www.jumpfeed.com/" }]
  },
  data() {
    return {
      backgroundVideoMP4: BackgroundVideoMP4,
      partnerLogos: [
        {
          partner: "AutoNation",
          logo: AutoNationLogo
        },
        {
          partner: "Media.net",
          logo: MediaNetLogo
        },
        {
          partner: "Web2Carz",
          logo: Web2CarzLogo
        },
        {
          partner: "Talent.com",
          logo: TalentComLogo
        },
        {
          partner: "Talroo",
          logo: TalrooLogo
        },
        {
          partner: "ActivEngage",
          logo: ActivEngageLogo
        }
      ],
      howTechWorksGraphic: HowTechWorksGraphic,
      howTechWorksGraphicWEBP: HowTechWorksGraphicWEBP
    };
  },
  mounted() {
    if (this.goToPartners) {
      this.scrollToPartners();
    }
  },
  computed: {
    goToPartners() {
      return this.$store.state.goToPartners;
    }
  },
  watch: {
    goToPartners(value) {
      if (value) {
        this.scrollToPartners();
      }
    }
  },
  methods: {
    scrollToPartners() {
      if (typeof this.$refs["partners"] !== "undefined") {
        window.scrollTo({
          top: this.$refs["partners"].offsetTop,
          behavior: "smooth"
        });
      }
      this.$store.commit("setGoToPartnersFlag", false);
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  position: relative;
  padding-top: $header-size;

  .home__video-container {
    position: absolute;
    top: 0;
    margin: 0;
    width: 100%;
    height: 100.33vh;
    overflow: hidden;
    opacity: 0.1;
    filter: grayscale(1);

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-image: radial-gradient($primary 33%, transparent 33%);
      background-size: 3px 3px;
    }

    .home__video {
      position: absolute;
      right: 0;
      bottom: 0;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .home__atf-content {
    @include containWidth();
    @include displayFlex(column, center, flex-start);
    @include fullScreenHeight();
    @include defaultContainerPadding();
    position: relative;
    z-index: 100;

    .home__cta-header {
      text-transform: uppercase;
      margin-top: 0;
      font-size: 3.25em;
      margin-bottom: $gap-large;
      //text-shadow: 1px 1px $primary;

      @include screenSmall {
        font-size: 3em;
      }
    }

    .home__cta-text {
      font-size: 1.25em;
      font-weight: 400;
      //text-shadow: 1px 1px $primary;
    }
  }

  .partners-content {
    @include defaultContainerPadding();
    position: relative;
    z-index: 100;
    text-align: center;
    background: $primary-light;

    h5 {
      @include screenSmall {
        font-size: 0.95em;
      }
    }

    .partners-grid {
      @include containWidth();

      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;

      @include screenMedium {
        grid-template-columns: 1fr 1fr;
      }

      .partner-logo-container {
        @include displayFlex(row, center, center);
        padding: $gap-large $gap-standard;
      }
      .partner-logo-image {
        width: 185px;

        @include screenMedium {
          width: max(115px, 18vw);
        }
      }
    }
  }

  .how-tech-works {
    position: relative;
    z-index: 100;
    background: $primary;

    .how-tech-works__content {
      @include containWidth();
      @include displayFlex(row, space-evenly, center);
      @include defaultContainerPadding();
      margin-bottom: $gap-large;

      @include screenMedium {
        @include displayFlex(column, center, flex-start);
      }

      .how-tech-work__header {
        font-size: 1.85em !important;
      }

      .how-tech-works__text {
        max-width: 50%;
        width: 620px;

        @include screenMedium {
          width: 100%;
          max-width: none;
          margin-top: $gap-large;
        }
      }

      .how-tech-works__graphic-container {
        @include screenMedium {
          margin: 0 auto;
          margin-top: $gap-large;
        }
      }

      .how-tech-works__graphic {
        width: 100%;
        max-width: 333px;
      }
    }
  }
}
</style>
