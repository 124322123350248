<template>
  <section class="app__loading-screen">
    <AppLogo class="app__loading-screen__logo" width="200px" />

    <div class="app__loading-screen__text">
      <small class="--text-uppercase" style="margin-right: 1rem"
        >Loading...</small
      >
      <h3 class="--no-margin">
        <span>{{ progressPercent }}</span
        >%
      </h3>
    </div>

    <div class="app__loading-screen__progress-bar">
      <div :style="{ width: progressPercent + '%' }"></div>
    </div>
  </section>
</template>

<script>
import AppLogo from "@/components/AppLogo";

export default {
  name: "AppLoadingScreen",
  components: {
    AppLogo
  },
  props: {
    progressPercent: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style scoped lang="scss">
.app__loading-screen {
  @include defaultContainerPadding();
  @include displayFlex(column, flex-end, flex-end);
  width: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 100000;
  background: $primary;

  @include screenSmall {
    min-height: 86vh;
    @include displayFlex(column, flex-end, center);
  }

  .app__loading-screen__logo {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .app__loading-screen__text {
    @include displayFlex(row, space-between, flex-end);
    width: min(400px, 100%);
  }

  .app__loading-screen__progress-bar {
    margin-top: $gap-standard;
    background-color: $primary-light;
    width: min(400px, 100%);
  }

  .app__loading-screen__progress-bar > div {
    @include transition(width);
    background-color: $accent;
    height: 6px;
  }
}
</style>
