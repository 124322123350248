export default {
  methods: {
    backToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    },
    preloadImage(url) {
      let img = new Image();
      img.src = url;
    }
  }
};
