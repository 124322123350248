<template>
  <footer class="footer">
    <AppLogo color="white" />

    <div class="footer__links">
      <router-link to="/privacy">Privacy Policy</router-link>
      <router-link to="/tos">Terms of Service</router-link>
    </div>

    <div class="footer__contact">
      <p class="footer__location">Chicago, IL</p>
      <a
        v-for="socialMedia in socialMedias"
        :key="socialMedia.name + '_socialMedia'"
        class="footer__social-media-link"
        :href="socialMedia.link"
        target="_blank"
      >
        <i :class="socialMedia.icon" />
      </a>
    </div>
  </footer>
</template>

<script>
import AppLogo from "./AppLogo";

export default {
  name: "TheFooter",
  components: {
    AppLogo
  },
  data() {
    return {
      socialMedias: [
        {
          name: "Facebook",
          icon: "fab fa-facebook-f",
          link: "https://facebook.com/jumpfeed"
        },
        {
          name: "Twitter",
          icon: "fab fa-twitter",
          link: "https://twitter.com/jumpfeed"
        },
        {
          name: "Instagram",
          icon: "fab fa-instagram",
          link: "https://instagram.com/jump_feed"
        },
        {
          name: "LinkedIn",
          icon: "fab fa-linkedin",
          link: "https://linkedin.com/company/jumpfeedmarketing"
        }
      ]
    };
  },
  methods: {},
  computed: {}
};
</script>

<style scoped lang="scss">
.footer {
  @include containWidth();
  @include displayFlex(row, space-between, center);
  padding: $gap-large $gap-very-large;
  background: $primary;
  color: #fff;

  @include screenMedium {
    padding: $gap-large;
  }

  @include screenSmall {
    padding: $gap-very-large $gap-large;
    @include displayFlex(column, flex-start, center);
  }

  .footer__links {
    font-size: 0.8em;
    text-transform: uppercase;
    font-weight: 300;

    @include screenSmall {
      margin: $gap-large 0;
      font-size: 0.85em;
      text-align: center;
    }

    a {
      margin: 0 $gap-standard;
      color: $gray;
      text-decoration: none;

      @include hoverLighten();

      @include screenSmall {
        margin: 0 0.75rem;
      }

      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .footer__contact {
    text-align: right;
    text-transform: uppercase;
    margin: 0;

    @include screenSmall {
      text-align: center;
    }

    .footer__location {
      margin: 0 0 $gap-small;
      font-weight: 500;

      @include screenSmall {
        margin-bottom: $gap-standard;
        font-size: 1em;
      }
    }

    .footer__social-media-link {
      text-decoration: none;
      color: $accent;
      margin: 0 $gap-standard;

      @include hoverLighten();

      @include screenSmall {
        font-size: 1.1em;
      }

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
</style>
