<template>
  <div id="app">
    <template v-if="appLoading">
      <AppLoadingScreen
        :progress-percent="appLoadingProgress"
        key="appLoadingScreen"
        :class="{ '--hide-loading-screen': appLoadingProgress === 100 }"
      />
    </template>
    <template v-else>
      <TheTitlebar />
      <div class="app__content" v-cloak>
        <transition appear name="fade" mode="out-in">
          <router-view />
        </transition>
      </div>
      <TheFooter />
    </template>

    <div id="preload_assets">
      <video class="home__video" muted autoplay playsinline>
        <source :src="preloadAssets.HomeBackgroundVideoMP4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <picture>
        <source
          :srcset="preloadAssets.HowTechWorksGraphicWEBP"
          type="image/webp"
        />
        <source :srcset="preloadAssets.HowTechWorksGraphic" type="image/png" />
        <img :src="preloadAssets.HowTechWorksGraphic" />
      </picture>

      <picture>
        <source :srcset="preloadAssets.AboutCoverPhotoWEBP" type="image/webp" />
        <source :srcset="preloadAssets.AboutCoverPhoto" type="image/png" />
        <img :src="preloadAssets.AboutCoverPhoto" />
      </picture>

      <img :src="preloadAssets.AutoNationLogo" />
      <img :src="preloadAssets.Web2CarzLogo" />
      <img :src="preloadAssets.MediaNetLogo" />
      <img :src="preloadAssets.TalrooLogo" />
      <img :src="preloadAssets.TalentComLogo" />
      <img :src="preloadAssets.ActivEngageLogo" />

      <picture>
        <source
          :srcset="preloadAssets.MeetTheTeamImageWEBP"
          type="image/webp"
        />
        <source :srcset="preloadAssets.MeetTheTeamImage" type="image/png" />
        <img :src="preloadAssets.MeetTheTeamImage" />
      </picture>
      <picture>
        <source :srcset="preloadAssets.ArenWellsImageWEBP" type="image/webp" />
        <source :srcset="preloadAssets.ArenWellsImage" type="image/png" />
        <img :src="preloadAssets.ArenWellsImage" />
      </picture>
      <picture>
        <source
          :srcset="preloadAssets.AustinMalliaImageWEBP"
          type="image/webp"
        />
        <source :srcset="preloadAssets.AustinMalliaImage" type="image/png" />
        <img :src="preloadAssets.AustinMalliaImage" />
      </picture>
    </div>
  </div>
</template>

<script>
import TheTitlebar from "@/components/TheTitlebar";
import TheFooter from "@/components/TheFooter";
import AppLoadingScreen from "@/components/AppLoadingScreen";

import HomeBackgroundVideoMP4 from "@/assets/videos/JF-Cover-Video.mp4";

import HowTechWorksGraphic from "@/assets/images/How-Tech-Works-Graphic.png";
import HowTechWorksGraphicWEBP from "@/assets/images/How-Tech-Works-Graphic.webp";

import AboutCoverPhoto from "@/assets/images/About-Cover-Photo.png";
import AboutCoverPhotoWEBP from "@/assets/images/About-Cover-Photo.webp";

import AustinMalliaImage from "@/assets/images/team/Austin-Mallia.png";
import AustinMalliaImageWEBP from "@/assets/images/team/Austin-Mallia.webp";

import ArenWellsImage from "@/assets/images/team/Aren-Wells.png";
import ArenWellsImageWEBP from "@/assets/images/team/Aren-Wells.webp";

import MeetTheTeamImage from "@/assets/images/team/Meet-The-Team.png";
import MeetTheTeamImageWEBP from "@/assets/images/team/Meet-The-Team.webp";

import AutoNationLogo from "@/assets/images/partner_logos/AutoNation.png";
import Web2CarzLogo from "@/assets/images/partner_logos/Web2Carz.png";
import MediaNetLogo from "@/assets/images/partner_logos/MediaNet.png";
import TalrooLogo from "@/assets/images/partner_logos/Talroo.png";
import TalentComLogo from "@/assets/images/partner_logos/TalentCom.png";
import ActivEngageLogo from "@/assets/images/partner_logos/ActivEngage.png";

export default {
  metaInfo: {
    title: "Jumpfeed",
    titleTemplate: "%s | Buy Media, Better."
  },
  components: {
    AppLoadingScreen,
    TheFooter,
    TheTitlebar
  },
  data() {
    return {
      /*appLoading: !window.sessionStorage.getItem(
        "jumpfeed.com-media-assets-loaded"
      ),*/
      appLoading: true,
      appLoadingProgress: 0,
      appLoadingInterval: null,
      preloadAssets: {
        HomeBackgroundVideoMP4,
        HowTechWorksGraphic,
        HowTechWorksGraphicWEBP,
        AboutCoverPhoto,
        AboutCoverPhotoWEBP,
        AutoNationLogo,
        Web2CarzLogo,
        MediaNetLogo,
        TalrooLogo,
        TalentComLogo,
        ActivEngageLogo,
        ArenWellsImage,
        ArenWellsImageWEBP,
        AustinMalliaImage,
        AustinMalliaImageWEBP,
        MeetTheTeamImage,
        MeetTheTeamImageWEBP
      }
    };
  },
  computed: {
    isHomePage() {
      return this.$route.name === "home";
    }
  },
  created() {
    if (this.appLoading) {
      let vm = this;
      this.appLoadingInterval = setInterval(function() {
        if (vm.appLoadingProgress <= 93) {
          vm.appLoadingProgress += Math.floor(Math.random() * 3);
        }
      }, 100);

      document.onreadystatechange = () => {
        //To load the app only after all libraries are loaded
        if (document.readyState === "complete") {
          let vm = this;
          setTimeout(function() {
            vm.appLoadingProgress = 100;
            clearInterval(vm.appLoadingInterval);
          }, 1000);
          setTimeout(function() {
            vm.appLoading = false;
            /*window.sessionStorage.setItem(
              "jumpfeed.com-media-assets-loaded",
              1
            );*/
          }, 2000);
        }
      };
    }
  }
};
</script>

<style lang="scss">
[v-cloak] {
  display: none;
}

#app {
  background: $primary;
  overflow-x: hidden;

  .app__content {
    position: relative;
    z-index: 1;
    //min-height: 100vh;
    @include fullScreenHeight();
    //margin-top: $header-size;
    background: $primary;
  }

  #preload_assets {
    display: none;
    opacity: 0;
  }

  .--hide-loading-screen {
    @include transition(all, 0.98s);
    //transform: translateY(-100%);
    opacity: 0;
  }
}
</style>
